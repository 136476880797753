import { createContext } from 'react';

export const ValidPictureMIMETypes = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/webp'
] as const;
export type ValidPictureMIMETypesTuple = typeof ValidPictureMIMETypes;
export type PictureMIMEType = ValidPictureMIMETypesTuple[number];

export type Languages = 'en' | 'de';

export type MapStyles = 'modern' | 'playful' | 'classic';

export const MAP_STYLES: {
  name: MapStyles;
  display_name: string;
  flat: string;
  three_dimensional: string;
}[] = [
  {
    name: 'modern',
    display_name: 'Modern',
    flat: 'mapbox://styles/kat-seferlis/ckrw3yihx2lgh17nvdprnrb7u',
    three_dimensional: 'mapbox://styles/kat-seferlis/ckwvz0yt0bpjk14o2q55r8em8'
  },
  {
    name: 'playful',
    display_name: 'Playful',
    flat: 'mapbox://styles/kat-seferlis/ckwvz2x8t0u8614sar6vsb0ai',
    three_dimensional: 'mapbox://styles/kat-seferlis/ckr56wp3s158g17muuwkpu1tl'
  },
  {
    name: 'classic',
    display_name: 'Classic',
    flat: 'mapbox://styles/kat-seferlis/ckr3kajlzgxe018o91fti68q3',
    three_dimensional: 'mapbox://styles/kat-seferlis/ckwvyk3t30tw815nuuaiddeia'
  }
];

export type BuildingTypes = 'flat' | 'three_dimensional';

export const BUILDING_TYPES: { name: BuildingTypes; display_name: string }[] = [
  { name: 'flat', display_name: 'Flat' },
  { name: 'three_dimensional', display_name: '3D' }
];
export const MAP_COLORS = [
  '#FF771F',
  '#FD2FC8',
  '#0059FF',
  '#842AFD',
  '#FB0000',
  '#E40D69',
  '#202020',
  '#01A754'
];

export const LANGUAGES: Languages[] = ['en', 'de'];

export const LANGUAGE_STORE_KEY = 'lang';
export const I18nContext = createContext<{
  language: string;
  store: { [lang: string]: Record<string, string> };
}>({
  language: 'en',
  store: { en: {}, de: {} }
});

export const BUBBLE_ZOOM_LEVELS = Object.freeze({
  0: [1, 2],
  1: [3, 4, 5],
  2: [6, 7],
  3: [8, 9, 10, 11],
  4: [12, 13, 14],
  5: [15, 16, 17, 18]
});

export const ROLE_BASED_MEMBER_INVITE_LABELS = {
    ADD_MEMBER: {
        reporter: 'BTN_ADD_REPORTER',
        admin: 'BTN_ADD_BUSINESS_ADMIN'
    },
    SUBMIT_SUCCESS: {
        reporter: 'HINT_REPORTER_CONFIRMATION',
        admin: 'HINT_BUSINESS_ADMIN_CONFIRMATION'
    },
};

export const MAP_URL_ORDINALS = ['first', 'second', 'third'];
